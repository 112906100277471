import React, { useState } from 'react';
import { environment, assets } from '../data';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {
  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  return (
    <div className="header">
      <div className="d-flex align-items-center justify-content-between py-3">
        <div className="logo">
          <Link
            to="/"
            className={
              activeLink === '/' || location.pathname === '/' ? '' : ''
            }
            onClick={() => handleLinkClick('/')}
          >
            <img src={assets.logo} alt="onepay logo" loading="lazy" />
          </Link>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div
            className="flex-column flex-sm-row align-items-start menu_space"
            style={{ cursor: 'pointer' }}
          >
            <Link
              to="/about_us"
              className={
                activeLink === '/about_us' || location.pathname === '/about_us'
                  ? 'active-link'
                  : ''
              }
              onClick={() => handleLinkClick('/about_us')}
            >
              <p>About Us</p>
            </Link>
          </div>

          <div
            className="flex-column flex-sm-row align-items-start menu_space"
            style={{ cursor: 'pointer' }}
          >
            <Link target="_blank" to="https://www.onepay.lk/offers.html">
              <p>Offers</p>
            </Link>
          </div>
          <div
            className="flex-column flex-sm-row align-items-start menu_space"
            style={{ cursor: 'pointer' }}
          >
            <Link
              to="/contact_us"
              className={
                activeLink === '/contact_us' ||
                location.pathname === '/contact_us'
                  ? 'active-link'
                  : ''
              }
              onClick={() => handleLinkClick('/contact_us')}
            >
              <p>Contact Us</p>
            </Link>
          </div>
          <div
            className="flex-column flex-sm-row align-items-start menu_space"
            style={{ cursor: 'pointer' }}
          >
            <Link target="_blank" to="https://dashboard.oneticket.lk/sign">
              <p>Login</p>
            </Link>
          </div>
          {/* <div className="flex-column flex-sm-row align-items-start">
            <p>info@onepay.lk</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
