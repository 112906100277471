//import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatUrl, formatDate, formatDate2 } from '../common';
import { assets, environment } from '../data';

export default function Event({ event, disable }) {
  console.log(event, 'eventevent');
  const navigate = useNavigate();

  //useEffect(() => {});

  return (
    <div className="event">
      <div className="banner-wrapper mb-1">
        <div
          className="banner"
          style={{
            backgroundImage: `url(${
              event?.event_banner
                ? environment.gpcStorage + event.event_banner
                : assets.sample_img
            })`,
          }}
        ></div>
      </div>
      {/* <div className="banner-wrapper mb-1">
        <img
          className="banner"
          src={
            event?.event_banner
              ? environment.gpcStorage + event.event_banner
              : assets.sample_img
          }
          alt="event cover"
          loading="lazy"
        />
      </div> */}

      <h3>{event?.event_name || 'Not Available'}</h3>
      <div className="mb-3">
        <span
          className={`active_label ${
            event?.is_indoor ? 'active_label_d' : 'active_label'
          }`}
        >
          {event?.is_indoor
            ? 'Indoor Musical Concert'
            : 'Outdoor Musical Concert'}
        </span>
      </div>

      <div className="location_display">
        <img src={assets.location} alt="Location" className="location_image" />
        <span className="location_text">{event?.venue || 'Not Available'}</span>
      </div>
      <div className="location_display">
        <img src={assets.clock} alt="Location" className="location_image" />
        <span className="location_text">
          {event.event_details === 'FRQM118C368CAF159CBEE'
            ? '2024/02/10 (Saturday) 6:00 PM'
            : event.event_details === 'DH36118E3A74B95C14157'
            ? '2024/03/16 (Saturday) 7:00 PM'
            : event.event_details === 'QX5C118E404233F1032E0'
            ? '2024/05/31 (Friday), 8:30 AM – 6:00 PM'
            : event.event_details === 'UIPA118E4147B2399589F'
            ? '2024/04/26 (Friday) 6:30 PM'
            : event.event_details === 'FCX5118E419E1E02A3EEB'
            ? '2024/05/17 (Friday) 7:00 PM'
            : formatDate2(event?.event_datetime)}
        </span>
      </div>
      <div className="inline-container mt-4">
        <span className="price-text">
          {event?.minimum_ticket_amount || ''}{' '}
          {event?.tickets_currency || 'LKR'}{' '}
          <span className="upward">Upwards</span>
        </span>
        <button
          className={`${disable ? 'buy-btn-disable' : 'primary-btn buy-btn'}`}
          disabled={disable}
          onClick={() => navigate(`/${event.event_details}`)}
        >
          Buy Tickets
        </button>
      </div>
    </div>
  );
}
